.ws-all-container {
  height: 1500px;
}

.ws-wrapper {
  background-color: #fff;
  padding-top: 8rem;
}

.ws-container {
  max-height: 1200px;
  display: grid;
  grid-template-columns: 1fr 0.9fr 1.1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  padding: 1rem;
  grid-template-areas:
    "image_workshop1 image_workshop1 image_workshop2"
    "image_workshop3 image_workshop3 image_workshop3"
    "image_workshop3 image_workshop3 image_workshop3"
    "image_workshop4 image_workshop4 image_workshop5";
}

.image_workshop1 {
  grid-area: image_workshop1;
  height: 100%;
}

.image_workshop2 {
  grid-area: image_workshop2;
  height: 100%;
}

.image_workshop3 {
  grid-area: image_workshop3;
  height: 100%;
}

.image_workshop4 {
  grid-area: image_workshop4;
  height: 300px;
}

.image_workshop5 {
  grid-area: image_workshop5;
  height: 300px;
}

.Navbar {
  width: 100%;
  height: 200px;
}

.image_workshop1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_workshop2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_workshop3 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_workshop4 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.image_workshop5 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.ws-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  text-align: center;
}

@media only screen and (max-width: 575.98px) {
  .ws-text > h1 {
    padding: 1rem 1rem;
    font-size: 24px;
  }

  .ws-container {
    display: block;
  }

  .image_workshop1 {
    grid-area: unset;
    height: 100%;
  }

  .image_workshop2 {
    grid-area: unset;
    height: 100%;
  }

  .image_workshop3 {
    grid-area: unset;
    height: 100%;
  }

  .image_workshop4 {
    grid-area: unset;
    height: 300px;
  }

  .image_workshop5 {
    grid-area: unset;
    height: 300px;
  }
}
